import BasicData from "./BasicData/BasicData.vue"
import WebhookData from "./WebhookData/WebhookData.vue"

export default ({
    components: { BasicData, WebhookData },

    data: () => ({
        currentStep: 1,
        webhookConfig: null,
    }),

    methods: {
        nextStep(webhookConfig = null) {
            this.webhookConfig = webhookConfig;
            this.currentStep++;
        },

        previousStep() {
            this.currentStep--;
        },
    }
})