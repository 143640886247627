import ExternalRequest from "./ExternalRequest/ExternalRequest.vue";
import Trigger from "./Trigger/Trigger.vue";

export default ({
    props: { webhook: Object },

    components: {
        ExternalRequest, Trigger
    },

    data: () => ({
        isUpdate: false,
        triggers: [
            { description: "Resgate é efetuado", key: "rescue" },
            { description: "Cliente é cadastrado", key: "registerPeople" },
        ],
        actions: [
            { description: "Requisição externa", key: "ExternalRequest" },
        ],
        selectedTrigger: "",
        selectedAction: "",
        validateForm: {
            required: [v => !!v || 'Campo obrigatório'],
        },
        getById: 0,
        create: 0,
        update: 0,
        webhookConfig: {},
    }),

    async created() {
        if (this.webhook) {
            this.webhookConfig = this.webhook;
            this.selectedTrigger = this.webhook.onAction;
        }
        this.isUpdate = !!this.$route.params.id;
        if (this.isUpdate) {
            this.selectedAction = "ExternalRequest";
        }
    },

    methods: {
        previousStep() {
            this.$emit("previous-step", this.webhookConfig);
        },

        goToList() {
            this.$router.push('/webhooks/list');
        },

        saveWebhook() {
            if (!this.selectedAction)
                return this.$toasted.global.error({ msg: 'Selecione uma ação.' });
            if (!this.$refs.form.validate())
                return this.$toasted.global.error({ msg: 'Preencha todos os campos.' });
            if (this.isUpdate)
                return this.updateWebhook();
            this.createWebhook();
        },

        updateWebhook() {
            this.update++;
        },

        createWebhook() {
            this.create++;
        },

        processSaveResult(result) {
            if (result.erro)
                return this.$toasted.global.error({ msg: 'Erro ao salvar webhook.' });
            this.$toasted.global.success({ msg: 'Webhook salvo com sucesso.' });
            this.goToList();
        },
    }
})