export const triggerVariables = [
    {
        trigger: "registerPeople",
        variables: [
            { key: "codigo_cliente", description: "Código do cliente cadastrado" },
            { key: "data_cadastro", description: "Data do cadastro" },
            { key: "estabelecimento_origem", description: "Id do estabelecimento" },
            { key: "id_cliente", description: "Id do cliente cadastrado" },
            { key: "ip_cadastro", description: "IP que acionou o cadastro" },
            { key: "origem_cadastro_cliente", description: "Origem do cadastro do cliente: admin, lojadepremios, api, integracao, hotsite, app, donuz_pdv, totem" },
            { key: "primeiro_nome", description: "Primeiro nome do cliente cadastrado" }
        ]
    },
    {
        trigger: "rescue",
        variables: [
            { key: "altura", description: "Altura do produto" },
            { key: "barcode", description: "Link do código de barras do resgate" },
            { key: "categoria", description: "Id da categoria do produto" },
            { key: "categoria_nome", description: "Nome da categoria do produto" },
            { key: "cliente_cpf", description: "Cpf do cliente que efetuou o resgate" },
            { key: "cliente_id", description: "Id do cliente que efetuou o resgate" },
            { key: "cliente_nome", description: "Nome do cliente" },
            { key: "codigo", description: "Código do produto" },
            { key: "comprimento", description: "Comprimento do produto" },
            { key: "data", description: "Data do resgate" },
            { key: "data_atualizacao", description: "Data de atualização do produto" },
            { key: "data_publicacao", description: "Data de publicação do produto" },
            { key: "data_vencimento", description: "Data de vencimento do resgate" },
            { key: "des_breve", description: "Descrição do produto" },
            { key: "estabelecimento_id", description: "Id do estabelecimento" },
            { key: "estoque", description: "Estoque do produto após o resgate" },
            { key: "estoque_padrao", description: "Estoque padrão do produto" },
            { key: "id_pedido", description: "Id do pedido" },
            { key: "id_produto", description: "Id do produto resgatado" },
            { key: "imagemAdicional1", description: "Link da imagem adicional 1 do produto" },
            { key: "imagemAdicional2", description: "Link da imagem adicional 2 do produto" },
            { key: "imagemAdicional3", description: "Link da imagem adicional 3 do produto" },
            { key: "imagemAdicional4", description: "Link da imagem adicional 4 do produto" },
            { key: "imagemAdicional5", description: "Link da imagem adicional 5 do produto" },
            { key: "imagemApp", description: "Link da imagem do produto exibida no app" },
            { key: "imagemPrincipal", description: "Link da imagem principal do produto" },
            { key: "largura", description: "Largura do produto" },
            { key: "metadado_1", description: "Metadado 1 do produto" },
            { key: "metadado_2", description: "Metadado 2 do produto" },
            { key: "metadado_3", description: "Metadado 3 do produto" },
            { key: "metadado_4", description: "Metadado 4 do produto" },
            { key: "peso", description: "Peso do produto" },
            { key: "precoDonuz", description: "Preço do produto em pontos" },
            { key: "precoReais", description: "Preço do produto em reais" },
            { key: "preco_vendido", description: "Preço vendido" },
            { key: "produto", description: "Descrição do produto" },
            { key: "produto_controle", description: "Código de controle do produto resgatado" },
            { key: "qrcode", description: "Link do qrcode do resgate" },
            { key: "qtd", description: "Quantidade resgatada do produto" },
            { key: "regras", description: "Regras do produto" },
            { key: "resgate_unico", description: "Produto pode ser resgatado uma única vez: 1 - sim, 0 - não" },
            { key: "solicitar_endereco_entrega", description: "Produto exige endereço de entrega: 1 - sim, 0 - não" },
            { key: "subcategoria", description: "Id da subcategoria do produto" },
            { key: "tags", description: "Tags do produto" },
            { key: "tamanho", description: "Tamanho do produto" },
            { key: "visibilidade", description: "Produto é visível na loja de prêmios: 1 - sim, 0 - não" },
            { key: "voucher", description: "Vouncher do resgate" }
        ]
    },
]