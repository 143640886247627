export default ({
    props: { webhook: Object },

    data: () => ({
        webhookConfig: {
            onAction: null,
            name: "",
            description: "",
            establishmentId: null,
            url: "",
            headers: {},
            json: "",
        },
        validateForm: {
            required: [v => !!v || 'Campo obrigatório'],
        }
    }),

    async created() {
        if (this.webhook) {
            this.webhookConfig = this.webhook;
        }
        if (this.isUpdate()) {
            return await this.getWebhookById();
        }
        this.webhookConfig.establishmentId = atob(sessionStorage.getItem('establishmentId'));
    },

    methods: {
        isUpdate() {
            return !!this.$route.params.id;
        },

        async getWebhookById() {
            const response = await this.DONUZ.Webhooks.GetById(this.$route.params.id);
            if (response.erro)
                return this.$toasted.global.error({ msg: 'Webhook não encontrado.' });
            this.webhookConfig = response;
        },

        nextStep() {
            if (!this.$refs.form.validate()) {
                return;
            }
            this.$emit("next-step", this.webhookConfig);
        },
    }
})