import Tooltip from '../../integrations/formIntegration/tooltip/Tooltip.vue';
import ModalDelete from '../../integrations/formIntegration/modalDelete/modalDelete.vue';

export default ({
    components: {
        Tooltip,
        ModalDelete
    },

    data: () => ({
        webhooks: [],
        modalDelete: false,
        idToDelete: null,
        establishmentId: "",
        triggers: [
            { description: "Resgate é efetuado", key: "rescue" },
            { description: "Ponto é inserido", key: "point" },
            { description: "Cliente é cadastrado", key: "registerPeople" },
        ],
    }),

    async created() {
        await this.getWebhooksByEstablishment();
    },

    methods: {
        async getWebhooksByEstablishment() {
            const establishmentId = atob(sessionStorage.getItem('establishmentId'))
            const response = await this.DONUZ.Webhooks.GetByEstablishment(establishmentId);
            this.webhooks.push(...response);
        },

        getTriggerDescription(trigger) {
            const findedTrigger = this.triggers.find(({ key }) => key === trigger);
            return findedTrigger.description;
        },

        async editWebhook(id) {
            this.$router.push({
                path: `/webhooks/form/${id}`,
            })
        },

        callDeleteWebhook(webhook) {
            this.idToDelete = webhook.id;
            this.establishmentId = String(webhook.establishmentId);
            this.toggleModalDelete();
        },

        toggleModalDelete() {
            this.modalDelete = !this.modalDelete;
        },

        async deleteWebhook(id) {
            const response = await this.DONUZ.Webhooks.Delete(id);
            if (response.status != 204)
                return this.$toasted.global.error({ msg: 'Erro ao deletar Automação.' })
            this.$toasted.global.success({ msg: 'Automação deletada com sucesso.' })
            this.toggleModalDelete()
            this.webhooks = this.webhooks.filter(webhook => webhook.id !== id);
        },
    }
})