import { mapGetters } from 'vuex';

export default {
    data: () => ({
        drawer: true,
        userAdmin: false,
        complexItems: [
            {
                icon: ICON.mdiFileDocumentOutline,
                title: "NF-e",
                featureName: "nfe_integration__",
                subitems: [
                    {
                        icon: ICON.mdiCogOutline,
                        to: "/nfe/list",
                        title: "Configuração"
                    },
                    {
                        icon: ICON.mdiFileChartOutline,
                        to: "/nfe/status/list",
                        title: "Status"
                    },
                ]
            },
            {
                icon: ICON.mdiFileDocument,
                title: "NFC-e",
                featureName: "nfce_integration__",
                subitems: [
                    {
                        icon: ICON.mdiCog,
                        to: "/nfc-e/list",
                        title: "Configuração"
                    },
                    {
                        icon: ICON.mdiAlertCircle,
                        to: "/nfc-e/invalid",
                        title: "Inválidos"
                    },
                ]
            },
        ],
        simpleItems: [
            {
                title: "Integrações API",
                icon: ICON.mdiApi,
                to: "/",
                featureName: "native_api_integration__",
            },
            {
                icon: ICON.mdiDatabase,
                to: "/database/list",
                title: "Integrações DB",
                featureName: "native_database_integration__",
            },
            {
                icon: ICON.mdiCogSync,
                to: "/webhooks/list",
                title: "Automações",
                featureName: "webhooks__",
            },
            {
                title: "Admin",
                icon: ICON.mdiAccountBox,
                to: "/admin",
                title: "Admin"
            },
            {
                title: "Sair",
                icon: ICON.mdiExitToApp,
                to: "/login",
                title: "Sair"
            }
        ],
        mini: false
    }),

    created() {
        sessionStorage.getItem('remember_token') ? this.userAdmin = true : this.userAdmin = false
    },

    computed: {
        ...mapGetters(["contractedResources"])
    },

    methods: {
        exit(title) {
            if (title == 'Sair') {
                sessionStorage.clear();
                this.$router.push('/login');
            }
        },

        featureIsContracted(feature) {
            if (!feature) return true;
            if (!this.contractedResources) return false;
            return this.contractedResources.includes(feature);
        },
    }
}