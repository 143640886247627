import { triggerVariables } from "./triggerVariables.js"

export default ({
    props: { selectedTrigger: String },

    data: () => ({
        selectedTrigger: null,
        selectedVariables: [],
        variableExample: "{{ codigo_cliente }}",
        jsonExample: `"{ "codigo_cliente": "{{ codigo_cliente }}" }"`,
    }),

    methods: {
        selectVariables(selectedTrigger) {
            const trigger = triggerVariables.find(({ trigger }) => trigger === selectedTrigger);
            if (!trigger) {
                return;
            }
            this.selectedVariables = trigger.variables;
        },
    },

    watch: {
        selectedTrigger: {
            handler(trigger) {
                this.selectedTrigger = trigger;
                this.selectVariables(trigger);
            },
            immediate: true
        },
    }
})