export default ({
    props: { webhook: Object, trigger: String, create: Number, update: Number },

    data: () => ({
        webhookConfig: {
            onAction: "",
            name: "",
            description: "",
            establishmentId: null,
            url: "",
            headers: {},
            json: "",
        },
        headers: [],
        validateForm: {
            required: [v => !!v || 'Campo obrigatório'],
        }
    }),

    async created() {
        if (this.webhook) {
            this.webhookConfig = this.webhook;
            this.setHeaders();
        }
        if (this.trigger) {
            this.webhookConfig.onAction = this.trigger;
        }
    },

    methods: {
        setHeaders() {
            Object.entries(this.webhookConfig.headers)
                .forEach(([key, value]) => this.addHeader(key, value));
        },

        addHeader(key = "", value = "") {
            this.headers.push({ key, value });
        },

        removeHeader(indexToRemove) {
            this.headers = this.headers.filter((_, index) => index != indexToRemove);
        },

        async createWebhook() {
            this.prepareHeaders();
            const response = await this.DONUZ.Webhooks.Create(this.webhookConfig);
            this.$emit("save-result", { error: Boolean(response.erro) });
        },

        async updateWebhook() {
            this.prepareHeaders();
            const response = await this.DONUZ.Webhooks.Update(this.webhookConfig);
            this.$emit("save-result", { error: Boolean(response.erro) });
        },

        prepareHeaders() {
            this.webhookConfig.headers = {};
            this.headers.forEach(({ key, value }) => {
                this.webhookConfig.headers[key] = value;
            });
        },
    },

    watch: {
        trigger: {
            handler(trigger) {
                this.webhookConfig.onAction = trigger;
            },
            immediate: true
        },

        create: {
            async handler(counter) {
                if (counter > 0) {
                    await this.createWebhook();
                }
            },
            immediate: true
        },

        update: {
            async handler(counter) {
                if (counter > 0) {
                    await this.updateWebhook();
                }
            },
            immediate: true
        },
    },
})