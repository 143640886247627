import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../template/views/home/Home.vue'
import Login from '../template/views/login/ViewLogin.vue'
import Admin from '../template/views/admin/ViewAdmin.vue'
import Register from '../template/views/register/Register.vue'
import Database from '../template/views/database/Database.vue'
import AllSystems from '../template/components/admin/allSystems/AllSystems.vue'
import DatabaseList from '../template/components/database/DatabaseList.vue'
import NFeList from '../template/views/nfe/list/NFeList.vue'
import NFeForm from '../template/views/nfe/form/NFeForm.vue'
import NFeStatusList from '../template/views/nfe/status/list/NFeStatusList.vue'
import NFCeList from '../template/views/nfce/list/NFCeList.vue'
import NFCeForm from '../template/views/nfce/form/NFCeForm.vue'
import InvalidNFCes from '../template/views/nfce/invalid-nfces/InvalidNfces.vue'
import WebhooksList from '../template/views/webhooks/list/WebhooksList.vue'
import WebhooksForm from '../template/views/webhooks/form/WebhooksForm.vue'

const config = require('../../config.json')

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
    meta: { loged: true }
  },
  {
    path: '/login/:tokenLogin?',
    name: 'Login',
    component: Login,
    meta: { loged: false },
    children: [
      {
        path: 'admin',
        name: 'LoginAdmin',
        component: () => import('../template/components/login/loginAdmin/LoginAdmin.vue')
      }
    ]
  },
  {
    path: '/admin',
    name: 'Admin',
    component: Admin,
    meta: { loged: true },
    children: [
      {
        path: 'todas-integracoes',
        name: 'TodasIntegracoes',
        component: () => import('../template/components/admin/allIntegrations/AllIntegrations.vue')
      },
      {
        path: 'cadastrar-sistema',
        name: 'CadastrarSistema',
        component: () => import('../template/components/admin/registerSystem/RegisterSystem.vue')
      },
      {
        path: 'editar-sistema',
        name: 'EditarSistema',
        props: true,
        component: () => import('../template/components/admin/registerSystem/RegisterSystem.vue')
      },
      {
        path: 'todos-sistemas',
        name: 'TodosSistemas',
        component: AllSystems
      }
    ]
  },
  {
    path: '/cadastrar',
    name: 'Register',
    component: Register,
    meta: { loged: true }
  },
  {
    path: '/database/list',
    name: 'DatabaseList',
    component: DatabaseList,
    meta: { loged: true }
  },
  {
    path: '/database/form/:id?',
    name: 'DatabaseForm',
    component: Database,
    meta: { loged: true }
  },
  {
    path: '/nfe/list',
    name: 'NFeList',
    component: NFeList,
    meta: { loged: true }
  },
  {
    path: '/nfe/form/:id?',
    name: 'NFeForm',
    component: NFeForm,
    meta: { loged: true }
  },
  {
    path: '/nfe/status/list',
    name: 'NFeStatusList',
    component: NFeStatusList,
    meta: { loged: true }
  },
  {
    path: '/nfc-e/list',
    name: 'NFCeList',
    component: NFCeList,
    meta: { loged: true }
  },
  {
    path: '/nfc-e/form/:id?',
    name: 'NFCeForm',
    component: NFCeForm,
    meta: { loged: true }
  },
  {
    path: '/nfc-e/invalid',
    name: 'InvalidNFCes',
    component: InvalidNFCes,
    meta: { loged: true }
  },
  {
    path: '/webhooks/list',
    name: 'WebhooksList',
    component: WebhooksList,
    meta: { loged: true }
  },
  {
    path: '/webhooks/form/:id?',
    name: 'WebhooksForm',
    component: WebhooksForm,
    meta: { loged: true }
  },
]

const router = new VueRouter({
  mode: 'history',
  base: config.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  var admin = sessionStorage.remember_token ? atob(sessionStorage.remember_token) : ''

  if (to.matched.some(record => record.meta.loged) && !sessionStorage.username && !sessionStorage.remember_token) {
    next({ name: 'Login' })
    return;
  }
  if (to.matched.some(record => record.meta.loged === false) && (sessionStorage.username || sessionStorage.remember_token)) {
    next({ name: 'Home' })
    return;
  }
  if (to.path.includes('/admin') && !sessionStorage.remember_token && to.name != 'LoginAdmin') {
    next({ name: 'Home' })
    return;
  }
  else
    next()
})

export default router
